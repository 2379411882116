<template>
  <div class="phone-tab">
    <country-code
      :validate="'required|numeric|min:9|max:11'"
      :name="$t('auth.phoneNumber')"
      :placeholder="$t('auth.phoneNumber')"
      v-model="user"
      @onSelect="onSelect"
    />
    <appButton
      :loading="loadingLoginButton"
      type="submit"
      @click.native="$emit('login', { user, country_code })"
    >
      {{ $t("auth.logIn") }}
    </appButton>
  </div>
</template>

<script>
import appButton from "../../../components/buttons/appButton.vue";
export default {
  components: { appButton },
  props: {
    loadingLoginButton: Boolean,
  },
  data() {
    return {
      user: "",
      country_code: "",
    };
  },
  methods: {
    onSelect(country_code) {
      this.country_code = country_code;
    },
  },
};
</script>

<style>
</style>