import { singleSignOn, transportation } from '@/axios';

export default {
  loginSignup (payload) {
    return singleSignOn().post('customer/login-or-signup', payload);
  },
  login (payload) {
    return singleSignOn().post('customer/login', payload);
  },
  forgetPassword (payload) {
    return singleSignOn().post('forget-password', payload);
  },
  requestResetPassword (payload) {
    return singleSignOn().post('customer/request-reset-password', payload);
  },
  verifyAccount (payload) {
    return singleSignOn().post('customer/verify-login', payload);
  },
  resetPassowrd (payload) {
    return singleSignOn().post('customer/reset-password', payload);
  },
  checkUser (paylod) {
    return singleSignOn().post('check', paylod);
  },
  register (payload) {
    return singleSignOn().post('customer/signup', payload);
  },
  sendOtp (payload) {
    return singleSignOn().post('send-otp', payload);
  },
  activeAccount (payload) {
    return singleSignOn().post('customer/verify-account', payload);
  },
  createTransportationUser (payload) {
    return transportation().post('users', payload);
  },
  sendFirebase (id, payload) {
    console.log(id);
    return transportation().patch('users/fcm-token', { fcm_token: payload });
  },
  getCities () {
    return singleSignOn().get('cities');
  },
  getAreas (cityId) {
    return singleSignOn().get(`areas?city_id=${ cityId }`);
  }
};
