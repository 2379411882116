<template>
  <b-row>
    <b-col cols="12">
      <div class="login-page">
        <div>
          <div class="flex-center">
            <img
              class="m-0"
              :src="require('@/assets/images/Souq-Alattara-logo.svg')"
              width="200"
              alt="Logo"
            />
          </div>
          <div class="tabs">
            <ul class="p-0">
              <li :class="{ active: tab === 'email' }">
                <button @click="tab = 'email'">
                  {{ $t("auth.email") }}
                </button>
              </li>
              <li :class="{ active: tab === 'phoneNumber' }">
                <button @click="tab = 'phoneNumber'">
                  {{ $t("auth.phoneNumber") }}
                </button>
              </li>
            </ul>
          </div>

          <transition>
            <ValidationObserver ref="form">
              <keep-alive>
                <component
                  :is="tab === 'email' ? 'EmailTab' : 'PhoneTab'"
                  :loadingLoginButton="loadingLoginButton"
                  @login="login"
                />
              </keep-alive>
            </ValidationObserver>
          </transition>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { core } from "@/config/pluginInit";
import authService from "../services/auth.js";
import appButton from "../../../components/buttons/appButton.vue";
import EmailTab from "../components/EmailTab.vue";
import PhoneTab from "../components/PhoneTab.vue";

export default {
  components: { appButton, EmailTab, PhoneTab },
  data() {
    return {
      // loadingCheckingButton: false,
      loadingLoginButton: false,
      tab: "email",
      submitCheckingForm: false,
    };
  },
  computed: {
    guestCart() {
      const cart = this.$store.getters["cart/getProductCart"];

      const products = cart.reduce((acc, item) => {
        const cartProducts = item.cart_products.map((item) => {
          return {
            product_id: item.id,
            quantity: item.quantity,
          };
        });
        return [...acc, ...cartProducts];
      }, []);
      return products;
    },
  },

  methods: {
    async login(userInfo) {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loadingLoginButton = true;
      const userData = {
        ...userInfo,
        otp_destination: this.tab,
        cart: this.guestCart,
      };
      try {
        const { data } = await authService.loginSignup(userData);
        localStorage.setItem("authData", JSON.stringify(userData));
        this.$router.push({ name: "otp" });
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingLoginButton = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  background: #fff;
  padding: 2rem;
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
  @media (max-width: 992px) {
    width: 100%;
    padding: 1rem;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs {
  width: 100%;
  margin: 1rem 0;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    li {
      list-style: none;
      min-width: 100px;
      button {
        background: transparent;
        border: none;
        padding: 0.25rem 1rem;
        border-radius: 4px;
        color: #000;
        font-weight: 600;
        font-size: 1rem;
        transition: all 0.2s ease;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      &.active {
        button {
          background: var(--iq-primary);
          color: #fff;
        }
      }
    }
  }
}
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}
.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
