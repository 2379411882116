<template>
  <div class="email-tab">
    <input-form
      class="mb-3"
      v-model="user"
      :validate="'required|email'"
      :placeholder="$t('auth.email')"
      :name="$t('auth.email')"
      type="email"
    />
    <appButton
      :loading="loadingLoginButton"
      type="submit"
      @click.native="$emit('login', { user })"
    >
      {{ $t("auth.logIn") }}
    </appButton>
  </div>
</template>

<script>
import appButton from "../../../components/buttons/appButton.vue";
export default {
  components: { appButton },
  props: {
    loadingLoginButton: Boolean,
  },
  data() {
    return {
      user: "",
      tab: "email",
    };
  },
};
</script>

<style>
</style>